@import "~antd/dist/antd.css";
@import "../assets/fonts/Gilroy/stylesheet.css";
@import "../assets/fonts/Poppins/poppins.css";
@import "../assets/fonts/SFPRO/SFPRO.css";
@import "../assets/fonts/Seahawks/Seahawks.css";
@import "../assets/fonts/Copperplate/Copperplate.css";
@import "../assets/fonts/Mariners/Mariners.css";
@import "../assets/fonts/SITickets/Industry.css";



@import "./table";
@import "./select";
@import "./input";
@import "./form";
@import "./calendar";
@import "./tabs";

.ant-modal-content {
  height: 100%;
  user-select: none;
  border-radius: 7px;
}

.ant-modal-header {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color: black;
}

.ant-spin-dot-item {
  background-color: white;
  color: #FFFFFF;
}

.ant-modal-close-x {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #FFFFFF;
}
